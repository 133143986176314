import EquipSlot from './EquipSlot';
export default class EquipmentPanel extends Phaser.GameObjects.Container {
	constructor(scene, x, y, atlas = 'main') {
		super(scene, x, y);
		scene.add.existing(this);
		const inventorybg = scene.add.image(0, 0, atlas, 'player_equipments_bg').setOrigin(0.5, 0);

		const inventorydecor = scene.add.image(-140, 200, atlas, 'player_equipments_decor');

		this.add(inventorybg);
		this.add(inventorydecor);

		this.bodyEquip = new EquipSlot(scene, inventorydecor.x, inventorydecor.y, 'body');
		this.headEquip = new EquipSlot(scene, this.bodyEquip.x, this.bodyEquip.y - 110, 'head');
		this.footEquip = new EquipSlot(scene, this.headEquip.x, this.bodyEquip.y + 110, 'foot');
		this.wingEquip = new EquipSlot(scene, this.headEquip.x + 110, this.bodyEquip.y, 'wing');
		this.tailEquip = new EquipSlot(scene, this.headEquip.x - 110, this.bodyEquip.y, 'tail');

		this.slot1 = new EquipSlot(scene, this.headEquip.x + 340, this.headEquip.y);
		this.slot1.disableBtn = true;
		this.slot2 = new EquipSlot(scene, this.bodyEquip.x + 340, this.bodyEquip.y);
		this.slot2.disableBtn = true;
		this.slot3 = new EquipSlot(scene, this.footEquip.x + 340, this.footEquip.y);
		this.slot3.disableBtn = true;

		this.add(this.headEquip);
		this.add(this.bodyEquip);
		this.add(this.footEquip);
		this.add(this.tailEquip);
		this.add(this.wingEquip);
		this.add(this.slot1);
		this.add(this.slot2);
		this.add(this.slot3);
	}

	updateEquipment(inputData) {
		if (inputData.body) this.bodyEquip.setArmor(inputData.body);
		if (inputData.head) this.headEquip.setArmor(inputData.head);
		if (inputData.foot) this.footEquip.setArmor(inputData.foot);
	}
}
