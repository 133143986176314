export default class NestEgg extends Phaser.GameObjects.Container {
	constructor(scene, x, y, atlas = 'main') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		const nestBack = scene.add.image(0, 0, atlas, 'nest_back');
		const eggIcon = scene.add.image(0, -30, atlas, 'egg_icon');
		const nestFront = scene.add.image(0, 0, atlas, 'nest_font');
		const hatchText = scene.add.image(0, 20, atlas, 'hatch_text');
		this.setSize(nestBack.width, nestBack.height * 2);
		this.setInteractive();

		this.add(nestBack);
		this.add(eggIcon);
		this.add(nestFront);
		this.add(hatchText);

		this.on('pointerover', function() {
			console.log('addad');
		});

		this.on('pointerdown', function() {
			this.scaleX = this.scaleY = 0.6;
		});

		this.on('pointerout', function() {
			this.scaleX = this.scaleY = 1;
		});

		this.on('pointerup', function() {
			this.scaleX = this.scaleY = 1;
		});
	}
}
