import { Boot } from './scenes/Boot';
import { Game } from './scenes/Game';
import { GameOver } from './scenes/GameOver';
import { MainMenu } from './scenes/MainMenu';
import { Preloader } from './scenes/Preloader';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';
import EaseMovePlugin from 'phaser3-rex-plugins/plugins/easemove-plugin.js';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';

import eruda from 'eruda';
import axios from 'axios';

// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, signInWithCustomToken, updateProfile } from 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyCZK_uitYBEEl1Ol48KyUeC_ojGaX9KZo0',
	authDomain: 'rooster-battle-ton.firebaseapp.com',
	projectId: 'rooster-battle-ton',
	storageBucket: 'rooster-battle-ton.appspot.com',
	messagingSenderId: '945101829011',
	appId: '1:945101829011:web:792a6d6c826942023f6e66',
	measurementId: 'G-NL46J330PD',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

eruda.init();

getData = async () => {
	console.log(Telegram.WebApp.initData);

	const response = await axios.post(
		'https://helloworld-jsjsbapnhq-uc.a.run.app?data=' + btoa(Telegram.WebApp.initData),
		{
			firstName: 'Fred',
			lastName: 'Flintstone',
			authenData: Telegram.WebApp.initData,
		},
		{
			headers: {
				'authen-data': Telegram.WebApp.initData,
			},
		}
	);
	const token = response.data;

	const auth = getAuth();
	const userCredential = await signInWithCustomToken(auth, token);
	console.log(userCredential);

	await updateProfile(auth.currentUser, {
		displayName: Telegram.WebApp.initDataUnsafe.user.first_name + ' ' + Telegram.WebApp.initDataUnsafe.user.last_name,
	});

	return userCredential.user;
};

startBattle = async () => {
	const startBattle = httpsCallable(functions, 'startBattle');
	const result = await startBattle();
	//console.log(result.data);
	return result.data;
};

summonItem = async () => {
	const summonItem = httpsCallable(functions, 'summonItem');
	const result = await summonItem();
	//console.log(result.data);
	return result.data;
};

equipPendingItem = async () => {
	const equipPendingItem = httpsCallable(functions, 'equipPendingItem');
	const result = await equipPendingItem();
	console.log(result.data);
	return result.data;
};

capitalizeFirstLetter = function(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

//  Find out more information about the Game Config at:
//  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config = {
	type: Phaser.AUTO,
	width: 720,
	height: 1280,
	parent: 'game-container',

	scale: {
		mode: Phaser.Scale.FIT,
		autoCenter: Phaser.Scale.CENTER_BOTH,
	},
	scene: [Boot, Preloader, MainMenu, Game, GameOver],
	plugins: {
		scene: [{ key: 'spine.SpinePlugin', plugin: spine.SpinePlugin, mapping: 'spine', sceneKey: 'spine' }],
		global: [
			{
				key: 'rexWebFontLoader',
				plugin: WebFontLoaderPlugin,
				start: true,
			},
			{
				key: 'rexEaseMove',
				plugin: EaseMovePlugin,
				start: true,
			},
			// ...
		],
	},
};

export default new Phaser.Game(config);
