import { Scene } from 'phaser';
import { Skin } from '@esotericsoftware/spine-phaser';

import ImageButton from '../components/ImageButton';
import TabBarButton from '../components/TabBarButton';
import NestEgg from '../components/NestEgg';
import EquipmentPanel from '../components/mainscene_component/EquipmentPanel';
import ProgressBar from '../components/ProgressBar';
import FloatingNumbersPlugin from '../components/FloatingNumbersPlugin';

export class MainMenu extends Scene {
	constructor() {
		super('MainMenu');
	}
	preload() {
		this.load.scenePlugin('floatingNumbersPlugin', FloatingNumbersPlugin, 'floatingNumbersPlugin', 'floatingNumbers');
	}
	create() {
		//Nen background mau tim
		this.gamebg = this.add.image(0, 0, 'main', 'bg');
		this.gamebg.x = this.gamebg.width / 2;
		this.gamebg.y = this.gamebg.height / 2;

		//Thanh bar chua cac thanh phan UI cua nua duoi man hinh
		this.bottomBar = this.add.image(0, 0, 'main', 'bottom_menu_bg');
		this.bottomBar.x = this.bottomBar.width / 2;
		this.bottomBar.y = this.gamebg.height - 110;

		this.inventorybg = new EquipmentPanel(this, this.gamebg.x, this.gamebg.y + 120);

		this.eggBtn = new NestEgg(this, this.gamebg.x, this.bottomBar.y + 30);

		this.button1 = new TabBarButton(this, 80, this.bottomBar.y + 55, 'button_brown', 'Battle');
		this.button2 = new TabBarButton(this, 210, this.bottomBar.y + 55, 'button_brown', 'Upgrade');

		this.button3 = new TabBarButton(this, 510, this.bottomBar.y + 55, 'button_brown', 'Leaderboard');
		this.button4 = new TabBarButton(this, 640, this.bottomBar.y + 55, 'button_brown', 'Reward');

		/*this.leftPlayer = this.add.spine(220, 500, 'nguyetcungke-data', 'nguyetcungke-atlas');
		this.leftPlayer.skeleton.setSkin(this.leftPlayer.skeleton.data.findSkin('silver'));
		this.leftPlayer.animationState.setAnimation(0, 'idle', true);

		this.rightPlayer = this.add.spine(500, 500, 'anthienke-data', 'anthienke-atlas');
		this.rightPlayer.skeleton.setSkin(this.rightPlayer.skeleton.data.findSkin('normal'));
		this.rightPlayer.animationState.setAnimation(0, 'idle', true);
		this.rightPlayer.scaleX = -1;*/

		this.battleInfo = [];
		this.currentTurn = -1;
		this.triggerTimer = this.time.addEvent({
			callback: this.timerEvent,
			callbackScope: this,
			delay: 4000, // 1000 = 1 second
			loop: true,
		});

		this.loadNewOpponent();
	}

	timerEvent() {
		if (this.battleInfo == null || this.battleInfo.length == 0 || this.currentTurn >= this.battleInfo.length - 1) {
			this.resetBattleField();
			this.loadNewOpponent();
			return;
		}
		this.currentTurn += 1;

		var attackChar = null;
		var hitChar = null;
		var attackSkill = null;
		var hpTrack = null;
		var skillBtn = null;

		if (this.currentTurn % 2 == 0) {
			attackChar = this.rightPlayer;
			//attackSkill = this.rightSkill;
			hitChar = this.leftPlayer;
			hpTrack = this.leftHP;
			//skillBtn = this.leftSkill;
		} else {
			attackChar = this.leftPlayer;
			//attackSkill = this.leftSkill;
			hitChar = this.rightPlayer;
			hpTrack = this.rightHP;
			//skillBtn = this.rightSkill;
		}

		attackChar.animationState.addListener({
			complete: entry => {
				attackChar.animationState.clearListeners();

				if (this.battleInfo[this.currentTurn].newHP >= 0) {
					attackChar.animationState.setAnimation(0, 'idle', true);
				}
			},
			event: (entry, event) => {
				if (event.data.name == 'hit') {
					console.log('event hit');
					/*if (attackSkill.skillName == 'skill_1' && this.battleInfo[this.currentTurn].isSkill) {
                        var impact = this.add.spine(hitChar.x, hitChar.y, 'dogkong-impact-data', 'dogkong-impact-atlas');
                        impact.animationState.addListener({
                            complete: entry => {
                                this.scene.remove(impact);
                                impact.destroy();
                            },
                        });
    
                        impact.setOrigin(0.5, 0.5);
                        impact.scaleX = 0.5;
                        impact.scaleY = 0.5;
                        impact.animationState.setAnimation(0, 'Skill', false);
                    }
                    if (attackSkill.skillName == 'skill_2' && this.battleInfo[this.currentTurn].isSkill) {
                        this.createNumber(hitChar, 5);
    
                        
                    } else {
                        
                    }*/

					this.createNumber(hitChar);

					if (hitChar == this.rightPlayer) {
						/* if (attackSkill.skillName == 'skill_2' && this.battleInfo[this.currentTurn].isSkill) {
                            this.createReward(1);
                        } else {
                            this.createReward();
                        }*/
						this.createReward();
					}

					hpTrack.setProgress(this.battleInfo[this.currentTurn].newHP);
					//skillBtn.setProgress(this.battleInfo[this.currentTurn].skillGauge);

					if (this.battleInfo[this.currentTurn].newHP < 0) {
						hitChar.animationState.setAnimation(0, 'die', false);
						attackChar.animationState.setAnimation(0, 'victory', true);
					} else {
						hitChar.animationState.setAnimation(0, 'hit 1', false);
						hitChar.animationState.addAnimation(0, 'idle', true);
					}
				}
			},
		});

		/*if (this.battleInfo[this.currentTurn].isSkill) {
            attackChar.animationState.setAnimation(0, 'Skill', false);
            attackSkill.resetSkill();
        } else {
            attackChar.animationState.setAnimation(0, 'NormalAttack', false);
        }*/

		attackChar.animationState.setAnimation(0, 'attack', false);
	}

	createNumber(targetObj, divide = 1) {
		if (this.currentTurn >= this.battleInfo.length) {
			return;
		}

		//console.log('current turn' + this.currentTurn);
		//console.log('battleInfo' + this.battleInfo.length);

		var dognumber = this.floatingNumbers.createFloatingText({
			textOptions: {
				fontFamily: 'Barlow Condensed',
				fontSize: 42,
				color: '#fff',
				strokeThickness: 2,
				fontWeight: 'bold',
				stroke: '#000000',
				shadow: {
					offsetX: 0,
					offsetY: 0,
					color: '#000',
					blur: 4,
					stroke: true,
					fill: false,
				},
			},
			text: Math.floor(this.battleInfo[this.currentTurn].damage / divide),
			align: 'top-center',
			parentObject: targetObj,
			animation: 'up',
			timeToLive: 500,
			animationEase: 'Sine.easeOut',
		});
	}

	resetBattleField() {
		if (this.leftPlayer) {
			this.leftPlayer.destroy();
		}

		if (this.rightPlayer) {
			this.rightPlayer.destroy();
		}

		if (this.myName) {
			this.myName.destroy();
		}

		if (this.oppName) {
			this.oppName.destroy();
		}

		if (this.leftSkill) {
			this.leftSkill.destroy();
		}

		if (this.rightSkill) {
			this.rightSkill.destroy();
		}

		if (this.leftHP) {
			this.leftHP.destroy();
		}

		if (this.rightHP) {
			this.rightHP.destroy();
		}
		this.battleInfo = null;
		this.currentTurn = -1;
	}

	async loadNewOpponent() {
		const battleResult = await startBattle();
		console.log(battleResult);

		this.myEquipments = battleResult.data.myData.equipments;

		this.inventorybg.updateEquipment(battleResult.data.myData.equipments);

		//this.updateEquipments();

		if (battleResult.data.opponent) {
			this.currentTurn = -1;
			this.battleInfo = battleResult.data.battleInfo;

			this.leftPlayer = this.add.spine(150, 500, battleResult.data.myData.skin + '-data', battleResult.data.myData.skin + '-atlas');
			console.log(this.leftPlayer.skeleton);
			const leftSkin = new Skin('custom');
			leftSkin.addSkin(this.leftPlayer.skeleton.data.findSkin('normal'));

			this.leftPlayer.skeleton.setSkin(leftSkin);
			this.leftPlayer.animationState.setAnimation(0, 'idle', true);

			this.rightPlayer = this.add.spine(550, 500, battleResult.data.opponentData.skin + '-data', battleResult.data.opponentData.skin + '-atlas');
			this.rightPlayer.skeleton.setSkin(this.rightPlayer.skeleton.data.findSkin('normal'));
			this.rightPlayer.animationState.setAnimation(0, 'idle', true);
			this.rightPlayer.scaleX = -1;

			//this.leftSkill = new SkillButton(this, 150, 600, battleResult.data.myData.skill.name, battleResult.data.myData.skill.dmg_gauge);
			//this.rightSkill = new SkillButton(this, 550, 600, battleResult.data.opponentData.skill.name, battleResult.data.opponentData.skill.dmg_gauge);

			this.leftHP = new ProgressBar(this, 50, 140, 'hp_green', battleResult.data.myData.hp);
			this.rightHP = new ProgressBar(this, 470, 140, 'hp_red', battleResult.data.opponentData.hp);

			this.myName = this.add.text(this.leftHP.x, this.leftHP.y - 40, battleResult.data.myData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'left' }).setOrigin(0, 0);
			this.oppName = this.add.text(this.rightHP.x + this.rightHP.width, this.rightHP.y - 40, battleResult.data.opponentData.displayName, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff', align: 'right' }).setOrigin(1, 0);
		}
	}

	createReward(maxParticle = 5) {
		const emitter = this.add.particles(550, 400, 'main', {
			frame: 'reward_exp_icon',
			bounce: 0.5,
			speed: { min: 600, max: 700 },
			maxVelocityX: 1200,
			maxVelocityY: 1200,
			angle: { min: -100, max: -80 },
			gravityY: 1600,
			//y: { start: 30, end: 500, ease: 'bounce.out' },
			lifespan: 4000,

			maxParticles: maxParticle,
			sortProperty: 'lifeT',
			sortOrderAsc: true,
		});

		emitter.addParticleBounds(0, 0, 700, 550, false, false, false, true);

		this.time.addEvent({
			callback: function() {
				emitter.gravityY = 0;
				const well = emitter.createGravityWell({
					x: -550,
					y: -550,
					power: 10,
					epsilon: 10,
					gravity: 1600,
				});
			},
			callbackScope: this,
			delay: 2000, // 1000 = 1 second
			loop: false,
		});
	}
}
