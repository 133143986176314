export default class EquipSlot extends Phaser.GameObjects.Container {
	constructor(scene, x, y, position, atlas = 'main') {
		super(scene, x, y);
		scene.add.existing(this);
		this.position = position;

		this.txt = scene.add.text(0, 0, '', { fontFamily: 'Barlow Condensed', fontSize: '24px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0);
		this.bg = scene.add.image(0, 0, atlas, 'equip_none_bg');

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		if (position != null) {
			this.icon = scene.add.image(0, 0, atlas, 'equip_' + position + '_icon');
			this.add(this.icon);
		}
		this.add(this.txt);

		this.on('pointerover', function() {});

		this.on('pointerdown', function() {});

		this.on('pointerout', function() {});

		this.on('pointerup', function() {});

		this.txt.y = this.height / 2 - 10;

		//this.setArmor({ material: 'gold', rarity: 1 });
	}

	setArmor(itemData) {
		var skinPosition = '';
		if (this.position == 'head') {
			skinPosition = 'helmet';
		}
		if (this.position == 'body') {
			skinPosition = 'chest';
		}

		if (this.position == 'foot') {
			skinPosition = 'leg';
		}

		if (skinPosition == '') {
			return;
		}

		if (this.icon) {
			this.icon.alpha = 0.3;
		}

		var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
		this.bg.setTexture('main', 'equip_rarity_' + color[itemData.rarity]);

		const frameName = capitalizeFirstLetter(itemData.material) + 'Armor/armor_' + itemData.material + '_' + skinPosition;
		const textureName = 'nguyetcungke-atlas!NguyetCungKe.png';

		const frm = this.scene.textures.getFrame(textureName, frameName);

		if (this.itemThumb) {
			this.itemThumb.setTexture(textureName, frameName);
		} else {
			this.itemThumb = this.scene.add.image(0, 0, textureName, frameName);
			this.add(this.itemThumb);
		}

		//this.itemThumb.scaleX = this.itemThumb.scaleY = 0.8;

		if (frm.rotated == true) {
			this.itemThumb.angle = -90;
		} else {
			this.itemThumb.angle = 0;
		}
	}

	setItem(itemData) {
		this.itemData = itemData;
		if (this.itemThumb) {
			this.itemThumb.setTexture('main', this.position + '_' + itemData.image);
		} else {
			this.itemThumb = this.scene.add.image(0, 0, 'main', this.position + '_' + itemData.image);
			this.add(this.itemThumb);
		}
		var color = ['gray', 'green', 'blue', 'purple', 'red', 'yellow'];
		this.bg.setTexture('main', 'equip_rarity_' + color[this.itemData.rarity]);
	}

	removeItem() {}

	setRarely(color) {}

	set disableBtn(value) {
		this._disabled = value;

		if (value == true) {
			this.removeInteractive();
			//this.setTexture('mainmenu',this.btnName+"_disable");
			this.bg.setTexture('main', 'equip_lock_bg');
		} else {
			this.setInteractive();
			this.bg.setTexture('main', 'equip_none_bg');
		}
	}
}
