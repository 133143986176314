export default class TabBarButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, text, atlas = 'main') {
		super(scene, x, y);

		this.btnName = name;
		scene.add.existing(this);
		this.txt = scene.add.text(0, 0, text, { fontFamily: 'Barlow Condensed', fontSize: '26px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0.5);
		this.txt.setShadow(2, 2, '#333333', 2);
		this.bg = scene.add.nineslice(0, 0, atlas, name + '_normal', 120, 0, 25, 25, 0, 0);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.txt);

		this.on('pointerover', function() {
			this.bg.setTexture('main', name + '_active');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture('main', name + '_active');
		});

		this.on('pointerout', function() {
			this.bg.setTexture('main', name + '_normal');
		});

		this.on('pointerup', function() {
			this.bg.setTexture('main', name + '_active');
		});
	}

	setText(value) {
		this.txt.text = value;
	}

	set disableBtn(value) {
		this._disabled = value;
		console.log(value);

		if (value == true) {
			this.removeInteractive();
			this.bg.setTexture('main', this.btnName + '_disable');
			//this.bg.setTexture('main', this.btnName + '_none');
		} else {
			this.setInteractive();
			this.bg.setTexture('main', this.btnName + '_none');
		}
	}
}
