import { Scene } from 'phaser';

export class Preloader extends Scene {
	constructor() {
		super('Preloader');
	}

	async init() {
		const loadingText = this.add.text(360, 450, '', { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: '#fff', align: 'center' }).setOrigin(0.5, 0);

		//  We loaded this image in our Boot Scene, so we can display it here
		this.add.image(360, 220, 'logo-512');

		//  A simple progress bar. This is the outline of the bar.
		this.add.rectangle(360, 684, 468, 14).setStrokeStyle(1, 0xffffff);

		//  This is the progress bar itself. It will increase in size from the left based on the % of progress.
		const bar = this.add.rectangle(360 - 230, 684, 4, 10, 0xffffff);

		//  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
		this.load.on('progress', progress => {
			//  Update the progress bar (our bar is 464px wide, so 100% = 464px)
			bar.width = 4 + 460 * progress;
		});
		this.connected = false;
		loadingText.text = 'Connecting to server...';
		console.log('Load data...');
		const user = await getData();
		//loadingText.text = user.uid + ' connected.';

		console.log('Connected.');
		this.connected = true;
	}

	preload() {
		//  Load the assets for the game - Replace with your own assets
		//this.load.setPath('assets');

		//this.load.image('logo', 'logo.png');
		this.load.atlas('main', './assets/roosterbattle_main.png', './assets/roosterbattle_main.json');

		this.load.spineBinary('nguyetcungke-data', './assets/rooster_spine/NguyetCungKe.skel');
		this.load.spineAtlas('nguyetcungke-atlas', './assets/rooster_spine/NguyetCungKe.atlas');
		this.load.spineBinary('anthienke-data', './assets/rooster_spine/AnThienKe.skel');
		this.load.spineAtlas('anthienke-atlas', './assets/rooster_spine/AnThienKe.atlas');

		this.load.spineBinary('bachlinhke-data', './assets/rooster_spine/BachLinhKe.skel');
		this.load.spineAtlas('bachlinhke-atlas', './assets/rooster_spine/BachLinhKe.atlas');

		this.load.spineBinary('tuhoanhke-data', './assets/rooster_spine/TuHoanhKe.skel');
		this.load.spineAtlas('tuhoanhke-atlas', './assets/rooster_spine/TuHoanhKe.atlas');
	}

	create() {
		const at = this.spine.getAtlas('nguyetcungke-atlas');
		var dataObj = { frames: {} };
		for (var i = 0; i < at.regions.length; i++) {
			const currentRegion = at.regions[i];
			dataObj.frames[at.regions[i].name] = { frame: { x: currentRegion.x, y: currentRegion.y, w: currentRegion.width, h: currentRegion.height } };
		}
		const returnTxt = this.textures.addAtlas('1231', this.textures.get('nguyetcungke-atlas!NguyetCungKe.png'), dataObj);

		//  When all the assets have loaded, it's often worth creating global objects here that the rest of the game can use.
		//  For example, you can define global animations here, so we can use them in other scenes.
		//  Move to the MainMenu. You could also swap this for a Scene Transition, such as a camera fade.
	}

	update() {
		if (this.connected) {
			this.scene.start('MainMenu');
		}
	}
}
